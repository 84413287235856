import "./styles.css";
import { useEffect, useState } from "react";
import { CrossIcon } from "./svgs/cross";
import { MicButton } from "./mic-button/component";
import { decisionGraph, Suggestion } from "./decision-graph";
import { pollySpeak, soundEffect } from "./polly";
import "./lib/aws-lex-audio";
import cx from "classnames";
import { OnboardingScreenOneSvg } from "./onboarding-screen-one";
import { OnboardingScreenTwoSvg } from "./onboarding-screen-two";

const ListeningCircles = () => (
  <div className="listening__circles-container">
    <div className="listening__outer-circle" />
    <div className="listening__inner-circle" />
  </div>
);

const OneInCircleSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#403B45" />
    <path
      d="M14.0938 18H12.2578V10.625C12.2578 9.74479 12.2786 9.04688 12.3203 8.53125C12.2005 8.65625 12.0521 8.79427 11.875 8.94531C11.7031 9.09635 11.1198 9.57552 10.125 10.3828L9.20312 9.21875L12.5625 6.57812H14.0938V18Z"
      fill="white"
    />
  </svg>
);

const TwoInCircleSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#403B45" />
    <path
      d="M16.4453 18H8.70312V16.6094L11.6484 13.6484C12.5182 12.7578 13.0938 12.1276 13.375 11.7578C13.6615 11.3828 13.8698 11.0312 14 10.7031C14.1302 10.375 14.1953 10.0234 14.1953 9.64844C14.1953 9.13281 14.0391 8.72656 13.7266 8.42969C13.4193 8.13281 12.9922 7.98438 12.4453 7.98438C12.0078 7.98438 11.5833 8.0651 11.1719 8.22656C10.7656 8.38802 10.2943 8.67969 9.75781 9.10156L8.76562 7.89062C9.40104 7.35417 10.0182 6.97396 10.6172 6.75C11.2161 6.52604 11.8542 6.41406 12.5312 6.41406C13.5938 6.41406 14.4453 6.69271 15.0859 7.25C15.7266 7.80208 16.0469 8.54688 16.0469 9.48438C16.0469 10 15.9531 10.4896 15.7656 10.9531C15.5833 11.4167 15.2995 11.8958 14.9141 12.3906C14.5339 12.8802 13.8984 13.5443 13.0078 14.3828L11.0234 16.3047V16.3828H16.4453V18Z"
      fill="white"
    />
  </svg>
);

const PaginationsSvg = () => (
  <svg
    width="38"
    height="13"
    viewBox="0 0 38 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="#403B45" />
    <circle cx="31.5" cy="6.5" r="6.5" fill="#D6C7C7" />
  </svg>
);

const PaginationsStep2Svg = () => (
  <svg
    width="38"
    height="13"
    viewBox="0 0 38 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="#403B45" />
    <circle cx="31.5" cy="6.5" r="6.5" fill="#403B45" />
  </svg>
);

const dummyResult: Suggestion = {
  imgUrl: "",
  link: "",
  title: "",
  description: ""
};
const suggestionsByIntent: Record<string, Suggestion[]> = {
  property_to_sell: [
    {
      imgUrl: "araban_ranches_agent.jpg",
      link:
        "https://www.propertyfinder.ae/en/find-agent/search?category_id=&location_id=130&nationality=&text=The%20Sustainable%20City",
      title: "Sustainable City | Find Agent",
      description:
        "13 matching agents found for The Sustainable City. Find community experts."
    },
    {
      imgUrl: "sustainable_city.jpeg",
      link:
        "https://www.propertyfinder.ae/en/trends/house-prices/dubai/the-sustainable-city?bedrooms=4&completion_status=secondary_property&property_type=35",
      title: "Sustainable City | House prices",
      description:
        "See house values in different communities and compare asking prices, actual transaction prices and rental yields."
    }
  ],
  avg_price: [
    {
      imgUrl: "sustainable_city.jpeg",
      link:
        "https://www.propertyfinder.ae/en/trends/house-prices/dubai/the-sustainable-city?bedrooms=4&completion_status=secondary_property&property_type=35",
      title: "Sustainable City | House prices",
      description:
        "See house values in different communities and compare asking prices, actual transaction prices and rental yields."
    }
  ],
  best_area_to_live: [
    {
      imgUrl: "palm_jumeirah.jpg",
      link:
        "https://www.propertyfinder.ae/en/community-guides/dubai/palm-jumeirah",
      title: "Palm Jumeirah | Community Guide",
      description:
        "Palm Jumeirah offers a resort lifestyle, with beaches, restaurants, cafes, and some of the world’s best hotels on your doorstep. It’s one of Dubai’s most prestigious addresses with most homes offering views of the magnificent Dubai coastline, Burj Al Arab, or Dubai Marina."
    },
    {
      imgUrl: "jumeirah.jpg",
      link: "https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah",
      title: "Jumeirah | Community Guide",
      description:
        "Look no further if you’re seeking a beachfront setting for your home. Jumeirah is one of the most popular communities in Dubai for a laid-back, luxurious lifestyle by the stunning coast."
    },
    {
      imgUrl: "jbr.jpg",
      link:
        "https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-beach-residence",
      title: "JBR | Community Guide",
      description:
        "Jumeirah Beach Residence (JBR) is the ultimate address for beachfront living. Its beachfront boulevard, The Walk, and The Beach mall are among the city’s best tourist attractions. The beach-facing development is instantly recognisable for its sandy-hued towers."
    },
    {
      imgUrl: "beaches.jpg",
      link: "https://www.propertyfinder.ae/blog/best-beaches-in-dubai/",
      title: "Best Beaches in Dubai | Blog",
      description:
        "Tick tock, tick tock, what time is it? It’s Summer o’clock! It’s the most anticipated time of the year when everyone can’t wait to jump in the water and become a fish! Ok, maybe not literally, but don’t we all want to spend as much time in the water as possible? There’s no stopping you from having fun, just don’t forget to follow the Dubai beach Rules."
    },
    {
      imgUrl: "daycation.jpg",
      link: "https://www.propertyfinder.ae/blog/daycations-in-dubai/",
      title: "Best Daycations in Dubai | Blog",
      description:
        "If you don’t have enough time for a staycation, no problem, you can go on a daycation then. Yes, many notable hotels in Dubai offer daycations with access to its beach and pool."
    },
    {
      imgUrl: "agent.jpg",
      link: "https://www.propertyfinder.ae/en/agent/ajay-singh-187120",
      title: "Ajay Singh | Community Expert",
      description:
        "Areas: Dubai Marina, Jumeirah 1, Jumeirah Beach Residence, Jumeirah Lakes Towers (JLT)"
    }
  ]
};

declare var AWS: any;
declare var LexAudio: any;

type Message = {
  type: "human" | "bot";
  text: string;
  boldify?: true;
};

export const tempSoundEffect = new Audio();
tempSoundEffect.src = "listening_beep.wav";
tempSoundEffect.load();

const playRecordSound = () => {
  soundEffect.src = "listening_beep.wav";
};

const waitMs = async (ms: number) => {
  await new Promise((resolve) => setTimeout(resolve, ms));
};
let recognition = new ((window as any).webkitSpeechRecognition ||
  (window as any).SpeechRecognition)();

recognition.continuous = true;
recognition.lang = "en-US";
recognition.interimResults = true;
recognition.maxAlternatives = 1;

const requestMic = () => {
  recognition.start();
  recognition.stop();
};

const Loader = () => {
  const [n, setN] = useState(0);

  useEffect(() => {
    const iid = setInterval(() => {
      setN((n) => (n + 1) % 7);
    }, 300);

    return () => clearInterval(iid);
  });

  let dots = ["."];

  for (let i = 0; i < n; i++) {
    dots.push(".");
  }

  return <div>{dots.join("")}</div>;
};

const onboarding_sentence1 = "Hello I’m Ezra, your virtual companion";
const onboarding_sentence2 = "You can say things like";

type OnboardingStep = "intro" | "tap_to_speak";

const Onboarding = ({
  examples,
  onboardingSpeaking,
  step
}: {
  examples: string[];
  step: OnboardingStep;
  onboardingSpeaking: boolean;
}) => (
  <div className={cx("onboarding__container", `onboarding--${step}`)}>
    {step === "intro" ? <OnboardingScreenOneSvg /> : <OnboardingScreenTwoSvg />}
    {step === "intro" && (
      <p className="onboarding__sentence_1">{onboarding_sentence1}</p>
    )}
    {step === "intro" && (
      <p className="onboarding__sentence_2">{onboarding_sentence2}...</p>
    )}
    {step === "intro" && (
      <div className="onboarding__examples">
        {examples.map((example, index) => (
          <p key={index} className="onboarding__example">
            {example}
          </p>
        ))}
      </div>
    )}
    {step === "tap_to_speak" && (
      <p className="onboarding__get-started-title">
        Get started in two easy steps
      </p>
    )}
    {step === "tap_to_speak" && (
      <div className="onboarding__step2-steps-container">
        <div className="onboarding__step2-steps-step onboarding__step2-steps-step--first">
          <OneInCircleSvg />
          <p>Tap the mic button and speak after the beep</p>
        </div>
        <div className="onboarding__step2-steps-step">
          <TwoInCircleSvg />
          <p>Then, tap the button again to end the recording</p>
        </div>
      </div>
    )}
    {step === "intro" && (
      <div className="onboarding__pagination">
        <PaginationsSvg />
      </div>
    )}
    {step === "tap_to_speak" && onboardingSpeaking && (
      <div className="onboarding__pagination">
        <PaginationsStep2Svg />
      </div>
    )}
  </div>
);

const tags = [
  "Hackathon",
  "beach",
  "Marina",
  "Dubai",
  "Arabian",
  "ranches",
  "mall",
  "park",
  "Three",
  "best",
  "area",
  "Buy",
  "self",
  "investment",
  "sell",
  "property",
  "off-plan",
  "off plan",
  "offplan",
  "completed",
  "Sustainable city",
  "yes"
];

const boldify = (text: string) => {
  let result = text;
  tags.forEach((tag) => {
    var strRegExp = new RegExp(tag, "gi");
    result = result.replace(strRegExp, " <b> " + tag + " </b> ");
  });

  return result;
};

const Conversation = ({ messageHistory }: { messageHistory: Message[] }) => (
  <div>
    {messageHistory.map((msg, i) => {
      const isLast = (i: number) => i === messageHistory.length - 1;
      const isSecondLast = (i: number) => i === messageHistory.length - 2;
      const isThirdLast = (i: number) => i === messageHistory.length - 3;
      return (
        <p
          key={i}
          className={cx("msg", `msg__${msg.type}`, {
            "msg--last": isLast(i),
            "msg--second-last": isSecondLast(i),
            "msg--third-last": isThirdLast(i),
            "msg--boldify": msg.boldify
          })}
          dangerouslySetInnerHTML={{
            __html: msg.boldify ? boldify(msg.text) : msg.text
          }}
        />
      );
    })}
  </div>
);

speechSynthesis.getVoices();

let conversation: any;

const hub: any = {};

const initConversation = () => {
  const config = {
    silenceDetection: false,
    lexConfig: {
      botName: "PropertyFinder"
    }
  };
  var creds = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-southeast-1:15cedc5a-ff75-43c6-ac2b-d4b89a36aaca"
  });
  AWS.config.update({
    region: "ap-southeast-1",
    credentials: creds
  });

  conversation = new LexAudio.conversation(
    config,
    function (state: any) {
      hub.onConversationStateChange(state);
      console.log("state", state);
    },
    function (data: any) {
      hub.onConversationDataReceived(data);
      console.log("Transcript: data, Response", data);
    },
    function (error: any) {
      console.log("error", error);
    },
    function (timeDomain: any, bufferLength: any) {
      //console.log("timeDomain, bufferLength", timeDomain, bufferLength);
      //waveform.visualizeAudioBuffer(timeDomain, bufferLength);
    }
  );
};
initConversation();

let conversationFulfiledFinishSpeakingPromiseResolve: Function | null = null;

let onboardingCanceled = false;
let msg;
export default function App() {
  const [isOnboarding, setIsOnboarding] = useState(true);
  const [opened, setOpened] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [listening, setListening] = useState(false);

  const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>("intro");
  const [onboardingSpeaking, setOnboardingSpeaking] = useState(false);
  const [results, setResults] = useState<Suggestion[]>([]);
  const [convesationState, setConverastionState] = useState("Passive");

  const [messageHistory, setMessageHistory] = useState<Message[]>([]);

  const addMessage = async (message: Message) => {
    setMessageHistory((messageHistory) => [
      ...messageHistory,
      { ...message, text: "" }
    ]);
    await waitMs(300);
    setMessageHistory((messageHistory) => {
      const newHistory = [...messageHistory];
      newHistory[newHistory.length - 1] = message;
      return newHistory;
    });
    if (message.type === "human") {
      await waitMs(500);
      setMessageHistory((messageHistory) => {
        const newHistory = [...messageHistory];
        newHistory[newHistory.length - 1] = { ...message, boldify: true };
        return newHistory;
      });
    }
  };

  hub.onConversationStateChange = (state: string) => {
    setConverastionState(state);
    if (
      state === "Passive" &&
      conversationFulfiledFinishSpeakingPromiseResolve
    ) {
      conversationFulfiledFinishSpeakingPromiseResolve();
    }
  };
  hub.onConversationDataReceived = async (data: any) => {
    if (data.dialogState !== "ElicitIntent") {
      setIsOnboarding(false);
      if (data.inputTranscript) {
        addMessage({
          type: "human",
          text: data.inputTranscript
            .replace("bitch", "beach")
            .replace("bye", "buy")
        });
        await waitMs(1500);
      }
      addMessage({ type: "bot", text: data.message });
    }
    if (data.dialogState === "Fulfilled") {
      await new Promise((resolve) => {
        conversationFulfiledFinishSpeakingPromiseResolve = resolve;
      });
      conversationFulfiledFinishSpeakingPromiseResolve = null;
      setResultsLoading(true);
      setMessageHistory([]);
      await waitMs(2500);
      setResults(suggestionsByIntent[data.intentName] || []);
      setResultsLoading(false);
    }
  };

  const Img = resultsLoading ? "div" : "img";
  const Link = resultsLoading ? "div" : "a";

  const micDisabled = ["Passive", "Listening"].indexOf(convesationState) === -1;

  return (
    <div>
      <div className="pf" />
      {resultsLoading || onboardingSpeaking || micDisabled ? null : (
        <MicButton
          onClick={async () => {
            if (micDisabled) {
              return;
            }
            if (results.length) {
              setResults([]);
            }
            soundEffect.src =
              "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

            if (!opened) {
              requestMic();
              setOpened(true);
              if (isOnboarding) {
                setOnboardingSpeaking(true);
                await pollySpeak(1);
                if (!onboardingCanceled) {
                  // user can close the dialog during onboarding in progress, but it will not exit this awaits
                  setOnboardingStep("tap_to_speak");
                  await pollySpeak(2);
                  if (!onboardingCanceled) {
                    // user can close the dialog during onboarding in progress, but it will not exit this awaits
                    setOnboardingSpeaking(false);
                  }
                }
              }
            } else {
              conversation.advanceConversation();
              playRecordSound();
              setListening((l) => !l);
            }
          }}
          active={listening}
        />
      )}
      <div className={cx("container", { "container--opened": opened })}></div>
      <div className={cx("dialog", { "dialog--opened": opened })}>
        {resultsLoading ? null : listening || convesationState === "Sending" ? (
          <div className="listening">
            <div>
              {convesationState === "Sending" ? <Loader /> : "Listening..."}
            </div>
          </div>
        ) : onboardingStep === "tap_to_speak" ? (
          onboardingSpeaking || convesationState !== "Passive" ? null : (
            <div className="listening">
              <p>Tap to speak</p>
            </div>
          )
        ) : null}
        {(listening || convesationState === "Sending") && <ListeningCircles />}
        {results.length || resultsLoading ? (
          <div className={cx("results__label", { loading: resultsLoading })}>
            {!resultsLoading && <p>{results.length} results</p>}
          </div>
        ) : null}
        {results.length || resultsLoading ? (
          <div className="result__shadow" />
        ) : null}
        {results.length || resultsLoading ? (
          <div className="results__container">
            {(resultsLoading
              ? [dummyResult, dummyResult, dummyResult, dummyResult]
              : results
            ).map((r, i) => (
              <>
                <Link
                  className={cx("results__item", {
                    "results__item--first": i === 0
                  })}
                  key={i}
                  href={resultsLoading ? "#" : r.link}
                  target={resultsLoading ? "" : "_blank"}
                  rel="noreferrer"
                >
                  <Img
                    className={cx("result__item-img", {
                      loading: resultsLoading
                    })}
                    src={`/suggestions/${r.imgUrl}`}
                    alt={r.title}
                  />
                  <div className="result_text-container">
                    <p
                      className={cx("result__item-title", {
                        loading: resultsLoading
                      })}
                    >
                      {r.title}
                    </p>
                    <p
                      className={cx("results__item-description", {
                        loading: resultsLoading
                      })}
                    >
                      {r.description}
                    </p>
                  </div>
                </Link>
                {results.length - 1 !== i && (
                  <div
                    className={cx("results__item-separator", {
                      loading: resultsLoading
                    })}
                  />
                )}
                {results.length - 1 === i && (
                  <div className="results__last-spacer" />
                )}
              </>
            ))}
          </div>
        ) : null}
        <CrossIcon
          className="cross"
          onClick={() => {
            setOpened(false);
            setListening(false);
            setIsOnboarding(false);
            setOnboardingStep("intro");
            setOnboardingSpeaking(false);
            setResultsLoading(false);
            setResults([]);
            soundEffect.pause();
            soundEffect.currentTime = 0;
            onboardingCanceled = true;
          }}
        />
        {isOnboarding ? (
          <Onboarding
            onboardingSpeaking={onboardingSpeaking}
            step={onboardingStep}
            examples={decisionGraph.paths
              .slice(0, 2)
              .map((path) => path.exampleText)}
          />
        ) : (
          <Conversation messageHistory={messageHistory} />
        )}
      </div>
      <div className="scroll-enabler" />
    </div>
  );
}
