import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { Polly } from "@aws-sdk/client-polly";
import { getSynthesizeSpeechUrl } from "@aws-sdk/polly-request-presigner";

const audioContext = new (window.AudioContext ||
  (window as any).webkitAudioContext)();

// Create the Polly service client, assigning your credentials
const client = new Polly({
  region: "ap-southeast-1",
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: "ap-southeast-1" }),
    identityPoolId: "ap-southeast-1:15cedc5a-ff75-43c6-ac2b-d4b89a36aaca" // IDENTITY_POOL_ID
  })
});

export const soundEffectForLoadingOnly = new Audio();
export const soundEffect = new Audio();
soundEffect.autoplay = true;

const speechCache: Record<string, string> = {};

export const getSpeechUrl = async (text: string) => {
  if (speechCache[text]) {
    return speechCache[text];
  }

  let url = await getSynthesizeSpeechUrl({
    client,
    params: {
      Engine: "neural",
      OutputFormat: "mp3", // For example, 'mp3'
      SampleRate: "16000", // For example, '16000
      Text: text, // The 'speakText' function supplies this value
      TextType: "text", // For example, "text"
      VoiceId: "Joanna" // For example, "Matthew"
    }
  });

  speechCache[text] = url as string;
  soundEffectForLoadingOnly.src = url as string;
  soundEffectForLoadingOnly.load();

  return url;
};

export const pollySpeak = async (part: 1 | 2): Promise<void> => {
  let resolvePromise = () => null;

  try {
    let url: string;
    if (part === 1) {
      url = "onboarding-part1-neural.mp3";
    } else {
      url = "onboarding-part2-neural.mp3";
    }

    soundEffect.src = url as string;
    soundEffect.addEventListener("canplaythrough", () => {
      const request = new XMLHttpRequest();
      request.open("GET", url as string, true);
      request.responseType = "arraybuffer";
      request.onload = function () {
        audioContext.decodeAudioData(request.response, function (buffer) {
          setTimeout(() => resolvePromise(), buffer.duration * 1000);
        });
      };
      request.send();
    });
  } catch (err) {
    console.log("Error", err);
    resolvePromise();
  }

  return new Promise((resolve) => {
    resolvePromise = resolve as any;
  });
};

/*
const onboardingSentence1 = `Hello, I’m Ezra, your virtual companion. You can say things like... ${decisionGraph.paths[0].exampleText}, or, ${decisionGraph.paths[1].exampleText}.`;
const onboardingSentence2 = `First. tap the mic button. and speak after the beep. then. Tap the button again, to end the recording.`;

const cacheSpeech = async () => {
  await getSpeechUrl(onboardingSentence1);
  await getSpeechUrl(onboardingSentence2);
};
cacheSpeech();
*/
