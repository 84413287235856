export const MicIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="28"
    height="42"
    viewBox="0 0 28 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 24.1602C16.5771 24.1602 18.4561 22.2432 18.4561 19.4248V10.8809C18.4561 8.0752 16.5771 6.14551 14 6.14551C11.4229 6.14551 9.54395 8.0752 9.54395 10.8809V19.4248C9.54395 22.2432 11.4229 24.1602 14 24.1602ZM4.36426 19.7168C4.36426 24.9346 7.83008 28.5146 12.8447 28.9971V31.5996H8.17285C7.52539 31.5996 6.97949 32.1328 6.97949 32.793C6.97949 33.4404 7.52539 33.9736 8.17285 33.9736H19.8271C20.4873 33.9736 21.0332 33.4404 21.0332 32.793C21.0332 32.1328 20.4873 31.5996 19.8271 31.5996H15.168V28.9971C20.1699 28.5146 23.6484 24.9346 23.6484 19.7168V17.2031C23.6484 16.543 23.1152 16.0225 22.4551 16.0225C21.7949 16.0225 21.249 16.543 21.249 17.2031V19.6279C21.249 23.9443 18.3291 26.8008 14 26.8008C9.6709 26.8008 6.75098 23.9443 6.75098 19.6279V17.2031C6.75098 16.543 6.21777 16.0225 5.55762 16.0225C4.89746 16.0225 4.36426 16.543 4.36426 17.2031V19.7168Z"
      fill="white"
    />
  </svg>
);
