import "./styles.scss";
import { MicIcon } from "../svgs/mic";

export const MicButton = ({
  active,
  onClick
}: {
  active: boolean;
  onClick: () => void;
}) => (
  <div className="mic-button__container" onClick={onClick}>
    <button id="speech" className="mic-button__btn" />
    {active && <div className="mic-button__pulse-ring"></div>}
    <MicIcon className="mic-button_icon" />
  </div>
);
