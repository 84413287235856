export type Choice = {
  text: string;
  value: string;
  keywords: string[];
};

export type Question = {
  text: string;
  id: string;
  choices: Choice[];
};

export type Answer = {
  questionId: string;
  value: string;
};

export type Suggestion = {
  imgUrl: string;
  link: string;
  title: string;
  description: string;
};

export enum PathId {
  hidden_content = "hidden_content",
  avg_price = "avg_price",
  broker = "broker"
}

/**
 * The list of questions to be answered to get the result list
 */
export type Path = {
  id: PathId;
  exampleText: string;
  keywords: string[];
  questions: Question[];
  suggestionsEvaluator: (answers: Answer[]) => Suggestion[];
};

export type DecisionTree = {
  paths: Path[];
};

enum QuestionId {
  buy_or_rent = "buy_or_rent",
  self_or_investment = "self_or_investment",
  scenic_type = "scenic_type"
}

enum BuyOrRentAnswerId {
  buy = "buy",
  rent = "rent"
}

const buyOrRentQuestion: Question = {
  id: QuestionId.buy_or_rent,
  text: "Are you looking to rent or buy?",
  choices: [
    { text: "Rent", value: BuyOrRentAnswerId.rent, keywords: ["rent"] },
    {
      text: "Buy",
      value: BuyOrRentAnswerId.rent,
      keywords: ["buy", "sale", "sell"]
    }
  ]
};

enum SelfOrInvestmentAnswerId {
  self = "self",
  investment = "investment"
}

const selfOrInvestmentQuestion: Question = {
  id: QuestionId.self_or_investment,
  text: "Is it for self or investment?",
  choices: [
    {
      text: "For my self",
      value: SelfOrInvestmentAnswerId.self,
      keywords: ["rent", "self", "me"]
    },
    {
      text: "For investment",
      value: SelfOrInvestmentAnswerId.investment,
      keywords: ["invest", "investment", "earn"]
    }
  ]
};

enum ScenicTypeAnswerId {
  beach_view = "beach_view",
  near_park = "near_park",
  mall_city = "mall_city",
  other = "other"
}

const scenicTypeQuestion: Question = {
  id: QuestionId.scenic_type,
  text: "What type of scenic?",
  choices: [
    {
      text: "Beach view",
      value: ScenicTypeAnswerId.beach_view,
      keywords: ["beach view"]
    },
    {
      text: "Near park",
      value: ScenicTypeAnswerId.near_park,
      keywords: ["park", "greens"]
    },
    {
      text: "Mall/City",
      value: ScenicTypeAnswerId.mall_city,
      keywords: ["mall", "city"]
    },
    {
      text: "Other",
      value: ScenicTypeAnswerId.other,
      keywords: ["other"]
    }
  ]
};

const hiddenContnetPath: Path = {
  id: PathId.hidden_content,
  exampleText: "What’s the best area to live in Dubai?",
  keywords: ["best", "area"],
  questions: [buyOrRentQuestion, selfOrInvestmentQuestion, scenicTypeQuestion],
  suggestionsEvaluator: (answers) => {
    return [
      {
        imgUrl: "palm_jumeirah.jpg",
        link:
          "https://www.propertyfinder.ae/en/community-guides/dubai/palm-jumeirah",
        title: "Palm Jumeriah | Community Guide",
        description:
          "Palm Jumeirah offers a resort lifestyle, with beaches, restaurants, cafes, and some of the world’s best hotels on your doorstep. It’s one of Dubai’s most prestigious addresses with most homes offering views of the magnificent Dubai coastline, Burj Al Arab, or Dubai Marina."
      },
      {
        imgUrl: "jumeirah.jpg",
        link:
          "https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah",
        title: "Jumeriah",
        description:
          "Look no further if you’re seeking a beachfront setting for your home. Jumeirah is one of the most popular communities in Dubai for a laid-back, luxurious lifestyle by the stunning coast."
      },
      {
        imgUrl: "jbr.jpg",
        link:
          "https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-beach-residence",
        title: "JBR | Community Guide",
        description:
          "Jumeirah Beach Residence (JBR) is the ultimate address for beachfront living. Its beachfront boulevard, The Walk, and The Beach mall are among the city’s best tourist attractions. The beach-facing development is instantly recognisable for its sandy-hued towers."
      },
      {
        imgUrl: "beaches.jpg",
        link: "https://www.propertyfinder.ae/blog/best-beaches-in-dubai/",
        title: "Best Beaches in Dubai | Blog",
        description:
          "Tick tock, tick tock, what time is it? It’s Summer o’clock! It’s the most anticipated time of the year when everyone can’t wait to jump in the water and become a fish! Ok, maybe not literally, but don’t we all want to spend as much time in the water as possible? There’s no stopping you from having fun, just don’t forget to follow the Dubai beach Rules."
      },
      {
        imgUrl: "daycation.jpg",
        link: "https://www.propertyfinder.ae/blog/daycations-in-dubai/",
        title: "Best Daycations in Dubai | Blog",
        description:
          "If you don’t have enough time for a staycation, no problem, you can go on a daycation then. Yes, many notable hotels in Dubai offer daycations with access to its beach and pool."
      },
      {
        imgUrl: "agent.jpg",
        link: "https://www.propertyfinder.ae/en/agent/ajay-singh-187120",
        title: "Ajay Singh | Community Expert",
        description:
          "Areas: Dubai Marina, Jumeirah 1, Jumeirah Beach Residence, Jumeirah Lakes Towers (JLT)"
      }
    ];
  }
};

const avgPricePath: Path = {
  id: PathId.avg_price,
  exampleText: "What is the average price for Dubai Marina apartments?",
  keywords: ["average price"],
  questions: [scenicTypeQuestion],
  suggestionsEvaluator: (answers) => {
    return [];
  }
};

const brokerPath: Path = {
  id: PathId.broker,
  exampleText: "Which brokerage should I choose for my search?",
  keywords: ["broker"],
  questions: [buyOrRentQuestion],
  suggestionsEvaluator: (answers) => {
    return [];
  }
};

export const decisionGraph: DecisionTree = {
  paths: [hiddenContnetPath, avgPricePath, brokerPath]
};
